//****************************
// NOTE: The following description needs to be verified as they are guesses by Nilesh gathering data from different resources.
//
// AngularJS has all of the building block components needed to integrate with back-end services.
// The $http object can be used to call a RESTful Backend Service (such as Node.js, Python, ASP.NET Web API others)
// All Angularjs internal services starts with "$" sign
//These services can be used within any Controller by just declaring them as dependencies. For example:
//
//	module.controller('FooController', function($http){
//		//...
//	});
//
//module.controller('BarController', function($window){
//	//...
//});
//
// http://weblogs.asp.net/dwahlin/using-an-angularjs-factory-to-interact-with-a-restful-service
// If you’re new to factories they can be created by calling the angular.module(‘YourModule"’).factory() function (note that modules can also create services, providers, values, and constants):
//		In this case then "aerosApp" is the name of the Module and "aerosApi" is the factory service
//
// “factory(name, providerFunction)“: registers a factory function responsible for creating the “singleton” service instance.
// Factories are singletons by default so the object returned by the factory can be re-used over and over by different controllers in the application
//
// The $http object is injected into the factory at runtime by AngularJS and used to make Ajax calls to the server.
// Looking through the code you can see that it exposes get(), post(), put(), and delete() functions to work with RESTful services
//
// Architectural Details:
// The browser calls into projectController.js the aerosApi.js to communicate with the ApplicationRoutes.java that has matching parameters to aeRosApiController.java.
// Upon success the projectController.js can react to the end users. For example: We will call back to projectConttroller.js and put up a toast message
//****************************
angular.module("aerosApp")
    .factory('aerosApi', ["$http", "Upload", "$rootScope", "CommonService",
        function ($http, Upload, $rootScope, CommonService) {

        var baseUrl = CommonService.baseUrl;

        function authenticate(email, password) {
            return $http.post(baseUrl + '/api/common/v1/authenticate', {email:email , password:password})
                .then(function(results) {
                    return $http.get('/api/common/v1/organizations', {headers: {
                            User:results.data
                        }})
                });
        }

        function loadOrganization(orgId) {
            return $http.get(baseUrl + "/organization/" + orgId + "/profile");
        }

        function updateContactInformation(orgId) {
            return $http.get(baseUrl + "/organization/" + orgId + "/contacts/{id}");
        }

        function getRogueUnits(orgId) {
            return $http.get(baseUrl + "/organization/" + orgId + "/rogueUnits");
        }

        function addRogueUnit(orgId, rogueUnit) {
            return $http.post(baseUrl + "/organization/" + orgId + "/rogueUnit", rogueUnit);
        }

        function deleteRogueUnit(orgId, rogueUnit) {
            return $http['delete'](baseUrl + "/organization/" + orgId + "/rogueUnit/" + rogueUnit);
        }

        function getOrganizationLicenses(orgId) {
            return $http.get(baseUrl + "/admin/organization/" + orgId + "/licenses");
        }

        function addLicenseToOrganization(orgId, key) {
            return $http.post(baseUrl + "/admin/organization/" + orgId + "/license/" + key);
        }

        function addUserToLicense(orgId, key, username, role) {
            //User needs username, orgId and string[] roles
            var user = {
                email: username,
                organizationId: orgId,
                roles: [role]
            };
            var request = {
                user: user
            };
            //return $http.post("/license/" + key + "/user", request);
            return $http.post(baseUrl + "/organization/" + orgId + "/license/" + key + "/user", request);
        }

        function removeUserFromLicense(orgId, key, username) {
            ///organization/{orgId}/license/{key}/user/{username}
            //return $http['delete']("/admin/license/" + key + "/user/" + username);
            return $http['delete'](baseUrl + "/organization/" + orgId + "/license/" + key + "/user/" + username);
        }

        function activateLicense(orgId, key) {
            return $http.put(baseUrl + "/admin/organization/" + orgId + "/license/" + key);
        }

        function loadOrganizationUsers(orgId) {
            return $http.get(baseUrl + "/organization/" + orgId + "/users");
        }

        function loadOrganizationUsersWithRoles(orgId, roles) {
            var roleParam = "";

            if (roles.length > 0) {
                roleParam = "?roles=" + roles[0];

                for (var i = 1; i < roles.length; i++) {
                    roleParam += "&roles=" + roles[i];
                }
            }

            return $http.get(baseUrl + "/organization/" + orgId + "/users" + roleParam);
        }

        function inviteUser(user, license) {
            var request = {
                user: user,
                license: license
            };
            return $http.post(baseUrl + "/organization/userInvitations", request);
        }

        function sendEmail(orgId) {
            return $http.post(baseUrl + "/organization/" + orgId + "/license/sendRequest");
        }

        function editUser(user) {
            return inviteUser(user);
        }

        function inviteProjectUser(user) {
            var request = {
                users: [
                    user
                ]
            };
            return $http.post(baseUrl + "/organization/projects/userInvitations", request);
        }

        function editProjectUser(user) {
            return inviteProjectUser(user);
        }

        function inviteProjectUsers(users) {
            var request = {
                users: users
            };
            return $http.post(baseUrl + "/organization/projects/userInvitations", request);
        }

        function inviteTechUser(user) {
            var request = {
                user: user
            };
            return $http.post(baseUrl + '/organization/adminInvitations', request);
        }

        function notifyTeamMembers(projectId, orgId) {
            return $http.post(baseUrl + "/organization/" + orgId + "/projects/" + projectId + "/notification");
        }

        function deleteUser(username, orgId) {
            return $http["delete"](baseUrl + "/organization/" + orgId + "/users/" + encodeURIComponent(username));
        }

        function deleteProjectUser(username, projectId, orgId) {

            return $http["delete"](baseUrl + "/organization/" + orgId + "/projects/" + projectId + "/user/" + username);
        }

        function loadProjects(orgId) {
            //TODO: changed
            return $http.get(baseUrl + "/organization/" + orgId + "/projects");
        }

        function loadHiddenProjects(orgId) {
            //TODO: changed
            return $http.get(baseUrl + "/organization/" + orgId + "/projects/hidden/list");
        }

        function loadActiveAndHiddenProjects(orgId) {
            //TODO: changed
            return $http.get(baseUrl + "/organization/" + orgId + "/projects/activeAndHidden/list");
        }

        function loadProject(projectId, orgId) {
            //TODO: changed
            return $http.get(baseUrl + "/organization/" + orgId + "/projects/" + projectId);
        }

        function getProjectChanged(projectId, since, orgId) {
            //TODO: changed
            return $http.get(baseUrl + "/organization/" + orgId + "/projects/" + projectId + "/changes?since=" + since);
        }

        function loadOrganizationUserInfo(orgId) {
            //TODO: changed
            return $http.get(baseUrl + "/organization/" + orgId + "/userprofile");
        }

        function loadProjectUserInfo(projectId, orgId) {
            //TODO: changed
            return $http.get(baseUrl + "/organization/" + orgId + "/userprofile/project/" + projectId);
        }


        function loadProjectUsers(projectId, orgId) {
            //TODO: changed
            return $http.get(baseUrl + "/organization/" + orgId + "/projects/" + projectId + "/users");
        }

        function loadProjectSummary(projectId, orgId) {
            return $http.get(baseUrl + "/organization/" + orgId + "/projects/" + projectId + "/results/summary");
        }

        function loadProjectResults(projectId, orgId) {
            //TODO: changed
            return $http.get(baseUrl + "/organization/" + orgId + "/projects/" + projectId + "/results");
        }

        function loadFiberGroupResults(projectId, orgId, toolType) {
            return $http.get(baseUrl + "/organization/" + orgId + "/project/" + projectId + "/fiberGroup/" + toolType.toLowerCase() + "/results");
        }

        function loadProjectResultsReport(projectId, orgId, request) {
            //TODO: changed
            return $http.post(baseUrl + "/organization/" + orgId + "/projects/" + projectId + "/reportresults", request);
        }

        function loadImageMetadata(imageId) {
            // NOTE: orgId is NOT currently in ApplicationRoutes image operations
            return $http.get(baseUrl + "/organization/image/" + imageId);
        }

        function getExportSorUrl(option) {
            return $http.post(baseUrl + "/organization/" + option.orgId + "/project/" + option.projectId + "/otdr/fibergroup/" + option.fiberGroupId + "/export", {
                fiberId: option.fiberId,
                otdrFileExportType: option.otdrFileExportType,
                wavelengths: option.wavelengths
            })
        }

        function exportSorFile(url) {
            return $http.get(url)
        }

        function importSorFiles(projectId, fields) {
            return Upload.upload({
                url: baseUrl + '/project/' + projectId + '/sor/file/import/sorFile',
                data: fields
            });
        }


        function deleteFiberGroups(fiberGroups, orgId) {
            var request = {
                ids: fiberGroups
            };

            return $http.post(baseUrl + "/organization/" + orgId + "/deleteFiberGroups", request);
        }

        function duplicateFiberGroups(fiberGroups, orgId) {
            var request = {
                ids: fiberGroups
            };

            return $http.post(baseUrl + "/organization/" + orgId + "/duplicateFiberGroups", request);
        }

        function generateTestResults(fiberGroupIds) {
            return $http.get(baseUrl + "/organization/generateTestResults/" + fiberGroupIds);
        }

        function removeTestSetupFromFiberGroup(testSetup, fiberGroup, orgId) {
            var request = {
                fiberGroupId: fiberGroup.id,
                testSetupKey: testSetup.key
            };

            return $http.post(baseUrl + "/organization/" + orgId + "/removeTestSetupFromFiberGroup", request);
        }

        function createFiberGroup(fiberGroup, projectId, orgId) {
            var request = {
                projectId: projectId,
                fiberGroup: fiberGroup
            };

            return $http.post(baseUrl + "/organization/" + orgId + "/createFiberGroup", request);
        }

        function addTestSetupToFiberGroups(testSetup, fiberGroups, orgId) {
            var data = {
                fiberGroupKeys: fiberGroups,
                testSetupKey: testSetup.key
            };

            return $http.post(baseUrl + "/organization/" + orgId + "/addTestSetupToFiberGroups", data);
        }

        function deleteFiberGroup(fiberGroup, orgId) {
            var request = {
                ids: [fiberGroup.id]
            };

            return $http.post(baseUrl + "/organization/" + orgId + "/deleteFiberGroups", request);
        }

        function saveFiberGroup(fiberGroup, projectId, orgId) {
            var request = {
                projectId: projectId,
                fiberGroup: fiberGroup
            };

            return $http.post(baseUrl + "/organization/" + orgId + "/saveFiberGroup/" + fiberGroup.id, request);
        }

        function createProject(project, orgId) {
            return Upload.upload({
                url: baseUrl + "/organization/" + orgId + "/projects",
                method: 'POST',
                data: {
                    projectName: project.name,
                    projectComment: project.comment,
                    fiberGroups: project.file,
                    delimiter: project.type
                }
            });
        }

        function editProject(projectId, project, orgId) {
            var req = {
                projectComment: project.comment
            };
            return $http.put(baseUrl + "/organization/" + orgId + "/projects/" + projectId + "/" + encodeURIComponent(project.name), req);
        }

        function copyProject(projectId, newProjectName, orgId, comment) {

            return $http.get(baseUrl + "/organization/" + orgId + "/projects/" + projectId + "/copyProject?name=" + newProjectName + "&comment=" + comment);
        }

        function mergeProjects(projectIds, newProjectName, mergeFgInBackground, orgId) {
            return $http({
                method: 'POST',
                url: baseUrl + "/organization/" + orgId + "/" + mergeFgInBackground + "/projects/" + projectIds[0] + "/" + projectIds[1],
                data: $.param({mergedProjectName: newProjectName}),
                headers: {'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8'}
            });
        }

        function deleteProject(projectId, orgId) {
            return $http["delete"](baseUrl + "/organization/" + orgId + "/projects/" + projectId);
        }

        function clearProjectMergeFlag(projectId, orgId) {
            return $http.put(baseUrl + "/organization/" + orgId + "/projects/" + projectId + "/cancel");
        }

        function updateProjectStatus(projectId, newStatus, orgId) {
            return $http.put(baseUrl + "/organization/" + orgId + "/projects/" + projectId + "/status/" + newStatus);
        }

        function createTestSetup(testType, testSetup, orgId) {
            var request = {
                testSetup: testSetup
            };

            return $http.post(baseUrl + "/organization/" + orgId + "/createTestSetup/" + testType, request);
        }

        function getTestSetups(orgId) {
            return $http.get(baseUrl + "/organization/" + orgId + "/testSetups")
                .then(function (resp) {
                    return resp.data;
                });
        }

        function getAllTestSetups(orgId) {
            return $http.get(baseUrl + "/organization/" + orgId + "/alltestSetups")
                .then(function (resp) {
                    return resp.data;
                });
        }

        function toggleTestSetupStatus(key, orgId) {
            return $http["delete"](baseUrl + "/organization/" + orgId + "/testSetup/" + key);
        }

        function getTestLimits(orgId) {
            return $http.get(baseUrl + "/organization/" + orgId + "/testLimits")
                .then(function (resp) {
                    return resp.data;
                });
        }

        function getTestLimitsWithArchived(orgId) {
            return $http.get(baseUrl + "/organization/" + orgId + "/alltestLimits")
                .then(function (resp) {
                    return resp.data;
                });
        }

        function createTestLimit(limit, orgId) {
            var request = {
                testLimit: limit
            };
            return $http.post(baseUrl + "/organization/" + orgId + "/testLimit", request);
        }

        function toggleTestLimitStatus(key, orgId) {
            return $http["delete"](baseUrl + "/organization/" + orgId + "/testLimit/" + key);
        }

        function requestReport(projectId, type) {
            return $http.post(baseUrl + "/projects/" + projectId + "/report", {
                report: 'TestResultsForProject', format: type
            })
                .then(function (resp) {
                    return resp.data.jobId;
                });
        }

        function requestNewReport(orgId, projectId, request) {
            return $http.post(baseUrl + "/organization/" + orgId + "/projects/" + projectId + "/report", request);
        }

        function isReportReady(projectId, jobId, orgId) {
            return $http.get(baseUrl + "/organization/" + orgId + "/projects/" + projectId + "/report/" + jobId + "/ready");
        }

        function requestPreview(orgId, projectId, request) {
            // /organization/{organizationId}/projects/{projectId}/report/preview
            return $http.post(baseUrl + "/organization/" + orgId + "/projects/" + projectId + "/report/preview", request);
        }

        function pollPreview(jobId) {
            return $http.get(baseUrl + "/preview/" + jobId + "/status");
        }

        function getPreview(jobId) {
            return $http.get(baseUrl + "/preview/" + jobId + "/results");
        }

        function userInvitationEmail(username, orgId) {
            return $http.get(baseUrl + "/api/sendMail/userInvitation?username=" + encodeURIComponent(username) + "&orgid=" + orgId);
        }

        function sendResetEmail(username) {
            return $http.post(baseUrl + "/sendResetEmail/" + encodeURIComponent(username));
        }

        // contacts for reports
        function getAllContacts(orgId) {
            return $http.get(baseUrl + "/organization/" + orgId + "/contacts");
        }

        function getContactById(orgId, contactId) {
            return $http.get(baseUrl + "/organization/" + orgId + "/contacts/" + contactId);
        }

        function createContact(orgId, request) {
            return $http.post(baseUrl + "/organization/" + orgId + "/contacts", request);
        }

        function editContact(orgId, contactId, request) {
            return $http.put(baseUrl + "/organization/" + orgId + "/contacts/" + contactId, request);
        }

        // used to set status "Deleted"
        function updateContactStatus(orgId, contact, status) {
            var contactId = contact.id;
            var request = contact;
            request.status = status;
            return editContact(orgId, contactId, request);
        }

        function setContactLogo(orgId, contactId, file) {
            return Upload.upload({
                url: baseUrl + '/organization/' + orgId + '/contacts/' + contactId + '/logo',
                fields: {},
                file: file
            });
        }

        function deleteContactLogo(orgId, contactId) {
            return $http["delete"](baseUrl + '/organization/' + orgId + '/contacts/' + contactId + '/logo');
        }

        function getAllProjectContacts(orgId, projectId) {
            return $http.get(baseUrl + "/organization/" + orgId + "/projects/" + projectId + "/contacts");
        }

        function getProjectContactById(orgId, projectId, contactId) {
            return $http.get(baseUrl + "/organization/" + orgId + "/projects/" + projectId + "/contacts/" + contactId);
        }

        function createProjectContact(orgId, projectId, request) {
            return $http.post(baseUrl + "/organization/" + orgId + "/projects/" + projectId + "/contacts", request);
        }

        function editProjectContact(orgId, projectId, contactId, request) {
            return $http.put(baseUrl + "/organization/" + orgId + "/projects/" + projectId + "/contacts/" + contactId, request);
        }

        function deleteProjectContact(orgId, projectId, contactId) {
            return $http["delete"](baseUrl + "/organization/" + orgId + "/projects/" + projectId + "/contacts/" + contactId);
        }

        function getAllReportConfigs(projectId, orgId) {
            return $http.get(baseUrl + "/organization/" + orgId + "/projects/" + projectId + "/reportrequest");
        }

        function createReportConfig(projectId, request, orgId) {
            return $http.post(baseUrl + "/organization/" + orgId + "/projects/" + projectId + "/reportrequest", request);
        }

        function updateReportConfig(projectId, requestName, request, orgId) {
            return $http.put(baseUrl + "/organization/" + orgId + "/projects/" + projectId + "/reportrequest" + encodeURIComponent(requestName), request);
        }

        function getRememberReportConfig(projectId, orgId) {
            return $http.get(baseUrl + "/organization/" + orgId + "/projects/" + projectId + "/reportrequest/rememberedrequest");
        }

        function saveRememberReportConfig(projectId, request, orgId) {
            return $http.post(baseUrl + "/organization/" + orgId + "/projects/" + projectId + "/reportrequest/rememberedrequest", request);
        }

        function createAttachmentCollection(config) {
            /*	config:
             * 		orgId
             * 		projectId
             * 		name
             * 		<targetType: "fiberGroup">
             * 		<targetId>
             */
            //	POST /organization/{orgId}/project/{projectId}/collection
            //	POST /organization/{orgId}/project/{projectId}/fibergroup/{fibergroupId}/collection
            var url = baseUrl + "/organization/" + config.orgId + "/project/" + config.projectId;
            var data = {
                name: config.name
            };
            switch (config.targetType) {
                case "Fibergroup":
                    url = url + "/fibergroup/" + config.targetId;
                    break;
            }
            url += "/collection";

            return $http.post(url, data);
        }

        function updateAttachmentCollectionName(config) {
            /*	config
             * 		orgId
             * 		projectId
             * 		collectionId
             * 		name
             */
            //	PUT /organization/{orgId}/project/{projectId}/collection/{collectionId}
            var url = baseUrl + "/organization/" + config.orgId + "/project/" + config.projectId + "/collection/" + config.collectionId;
            var data = {
                name: config.name
            };
            return $http.put(url, data);
        }

        function deleteAttachmentCollection(config) {
            /*	config
             * 		orgId
             * 		projectId
             * 		collectionId
             * 		<fiberGroupId>
             */
            //	DELETE /organization/{orgId}/project/{projectId}/collection/{collectionId}
            //	DELETE /organization/{orgId}/project/{projectId}/fibergroup/{fibergroupId}/collection/{collectionId}
            var url = baseUrl + "/organization/" + config.orgId + "/project/" + config.projectId;
            if (config.fiberGroupId) {
                url += "/fibergroup/" + config.fiberGroupId;
            }
            url += "/collection/" + config.collectionId;
            return $http['delete'](url);
        }

        function getAttachmemtCollectionDetails(config) {
            /*	config
             * 		orgId
             * 		projectId
             * 		collectionId
             */
            //	GET /organization/{orgId}/project/{projectId}/collection/{collectionId}
            return $http.get(baseUrl + "/organization/" + config.orgId + "/project/" + config.projectId + "/collection/" + config.collectionId);
        }

        function addAttachmentToCollection(config) {
            /*	config
             * 		orgId
             * 		projectId
             * 		collectionId
             * 			<fiberId>		// TODO: update provisional fiber attachment API if needed
             * 		file
             * 		comment
             * 		fileName
             */
            //	POST /organization/{orgId}/project/{projectId}/collection/{collectionId}/attachment
            //	POST /organization/{orgId}/project/{projectId}/collection/{collectionId}/fiberId/{fiberId}/attachment
            var url = baseUrl + "/organization/" + config.orgId + "/project/" + config.projectId + "/collection/" + config.collectionId;
            if (config.fiberId) {
                url += "/fiberId/" + config.fiberId;
            }
            url += "/attachment";
            var data = {
                "file": config.file,
                "comment": config.comment,
                "fileName": config.fileName
            };
            return Upload.upload({
                method: 'POST',
                url: url,
                data: data
            });
        }

        function updateAttachment(config) {
            /*	config
             * 		orgId
             * 		projectId
             * 		collectionId
             * 		attachmentId
             * 			<fiberId>	// TODO: update provisional fiber attachment API if needed
             * 		<file>		// at least 1 of 3 attachment properties below must be set
             * 		<comment>
             * 		<fileName>
             */
            //	PUT /organization/{orgId}/project/{projectId}/collection/{collectionId}/attachment/{attachmentId}
            //	PUT /organization/{orgId}/project/{projectId}/collection/{collectionId}/fiberId/{fiberId}/attachment/{attachmentId}
            if (!config.file && !config.comment && !config.fileName) {
                return false;
            }
            var url = baseUrl + "/organization/" + config.orgId + "/project/" + config.projectId + "/collection/" + config.collectionId;
            if (config.fiberId) {
                url += "/fiberId/" + config.fiberId;
            }
            url += "/attachment/" + config.attachmentId;

            var data = {};
            if (config.comment) {
                data.comment = config.comment;
            }
            if (config.file) {
                data.file = config.file;
            }
            if (config.fileName) {
                data.fileName = config.fileName;
            }
            return Upload.upload({
                method: 'PUT',
                url: url,
                data: data
            });
        }

        function deleteAttachment(config) {
            /*	config
             * 		orgId
             * 		projectId
             * 		collectionId
             * 		attachmentId
             * 		<fiberGroupId> or <fiberId>
             */
            //	DELETE /organization/{orgId}/project/{projectId}/collection/{collectionId}/attachment/{attachmentId}
            //	DELETE /organization/{orgId}/project/{projectId}/fibergroup/{fiberGroupId}/collection/{collectionId}/attachment/{attachmentId}
            // 	DELETE /organization/{orgId}/project/{projectId}/collection/{collectionId}/fiberId/{fiberId}/attachment/{attachmentId}
            var url = baseUrl + "/organization/" + config.orgId + "/project/" + config.projectId;
            if (config.fiberGroupId) {
                url += "/fibergroup/" + config.fiberGroupId;
            }
            url += "/collection/" + config.collectionId;
            if (config.fiberId) {
                url += "/fiberId/" + config.fiberId;
            }
            url += "/attachment/" + config.attachmentId;

            return $http['delete'](url);
        }

        var genericToolLibraryAPI = {
            "list": function list(toolType, orgID) {
                return $http.get(baseUrl + '/organization/' + orgID + '/' + toolType + '/tools');
            },
            "get": function get (toolType, orgID, toolSettingsID) {
                return $http.get(baseUrl + '/organization/' + orgID + '/' + toolType + '/tools/' + toolSettingsID);
            },
            "post": function post(toolType, orgID, data) {
                return $http.post(baseUrl + '/organization/' + orgID + '/' + toolType + '/tools', data);
            },
            "update": function update(toolType, orgID, toolSettingsID, data) {
                return $http.put(baseUrl + '/organization/' + orgID + '/' + toolType + '/tools/' + toolSettingsID, data);
            },
            "delete": function (toolType, orgID, toolSettingsID) {
                return $http.delete(baseUrl + '/organization/' + orgID + '/' + toolType + '/tools/' + toolSettingsID);
            }
        };

        var otdrToolLibraryAPI = {
            "list": function (orgID) {
                return genericToolLibraryAPI.list('otdr', orgID);
            },
            "get": function (orgID, toolSettingsID) {
                return genericToolLibraryAPI.get('otdr', orgID, toolSettingsID);
            },
            "post": function (orgID, data) {
                return genericToolLibraryAPI.post('otdr', orgID, data);
            },
            "update": function (orgID, toolSettingsID, data) {
                return genericToolLibraryAPI.update('otdr', orgID, toolSettingsID, data);
            },
            "settings": {
                "list": function (orgID) {
                    return $http.get(baseUrl + '/organization/' + orgID + '/otdr/tools/limits');
                },
                "post": function (orgID, data) {
                    return $http.post(baseUrl + '/organization/' + orgID + '/otdr/tools/limits', data);
                },
                "get": function (orgID, presetID) {
                    return $http.get(baseUrl + '/organization/' + orgID + '/otdr/tools/limits/' + presetID);
                },
                "update": function (orgID, presetID, data) {
                    return $http.put(baseUrl + '/organization/' + orgID + '/otdr/tools/limits/' + presetID, data);
                },
                "delete": function (orgID, presetID) {
                    return $http.delete(baseUrl + '/organization/' + orgID + '/otdr/tools/limits/' + presetID);
                }
            }
        };

        // TOOLS
        function getOrgToolsByType(config) {
            var url = baseUrl + "/organization/" + config.orgId + "/" + config.toolType + "/tools";
            return $http.get(url);
        }

        function getProjectToolsByType(config) {
            var url = baseUrl + "/organization/" + config.orgId + "/projects/" + config.projectId + "/" + config.toolType + "/tools";
            return $http.get(url);
        }

        function applyProjectToolToFiberGroupsByToolType(config) {
            var url = baseUrl + "/organization/" + config.orgId + "/projects/" + config.projectId + "/" + config.toolType + "/fibergroups/tool";
            var request = {
                fiberGroupWebKeys: config.fiberGroupsArray,
                toolId: config.toolId
            };
            return $http.post(url, request);
        }

        function removeProjectToolFromFiberGroupsByToolType(config) {
            var method = "delete";
            var url = baseUrl + "/organization/" + config.orgId + "/projects/" + config.projectId + "/" + config.toolType + "/fibergroups/tool";
            var request = {
                fiberGroupWebKeys: config.fiberGroupsArray
            };
            switch (config.toolType) {
                case "otdr":
                    request.toolId = config.toolId;
                    break;
            }

            var config = {
                method: method,
                url: url,
                data: request,
                headers: {"Content-Type": "application/json;charset=utf-8"}
            };

            return $http(config);
            //return $http[method](url, request, headers);
        }

        function createProjectToolByType(config) {
            var method = "post";
            var url = baseUrl + "/organization/" + config.orgId + "/projects/" + config.projectId + "/" + config.toolType.toLowerCase() + "/tools";
            var request = {
                tool: config.tool
            };
            return $http[method](url, request);

        }

        function updateProjectToolByType(config) {
            var method = "put";
            var url = baseUrl + "/organization/" + config.orgId + "/projects/" + config.projectId + "/" + config.toolType + "/tools/" + config.toolId;
            var request = {
                overwrite: config.overwrite,
                tool: config.tool
            };
            return $http[method](url, request);

        }

        function deleteProjectToolByType(config) {
            var method = "delete";
            var url = baseUrl + "/organization/" + config.orgId + "/projects/" + config.projectId + "/" + config.toolType + "/tools/" + config.toolId + "?overwrite=" + !!config.overwrite;
            return $http[method](url);
        }

        function getToolsAppliedToProject(config) {
            var method = "get";
            var url = baseUrl + "/organization/" + config.orgId + "/projects/" + config.projectId + "/fibergroups/tools";
            return $http[method](url);
        }

        function getFiberTypes() {
            var method = "get";
            var url = baseUrl + "/api/fiberType";

            return $http[method](url);
        }

        function getSimpleFiberTypes() {
            var method = "get";
            var url = baseUrl + "/api/simpleFiberType";

            return $http[method](url);
        }

        function getTraceViewerData(config) {
            return $http.get(baseUrl + '/organization/' + config.organizationId + '/project/' + config.projectId + '/fibergroup/'
                + config.fiberGroupId + '/fiber/' +  config.fiberId + (config.isAdjusted ? '/adjustedtrace' : '/trace'));
        }

        function getTraceViewerEvents(config) {
            // return $http.get('organization/5246869487747072/project/422ea6da-d723-4b8d-b99b-4933073bf5cc/fibergroup/0d4dec1a-bd8d-4a5a-ac45-1060aa1fb9a4/fiber/1/events');
            return $http.get(baseUrl + '/organization/' + config.organizationId + '/project/' + config.projectId + '/fibergroup/' + config.fiberGroupId + '/fiber/' + config.fiberId + '/events');
        }

        function getOTDRTestResults(config) {
            var method = "get";
            var url = baseUrl + "/organization/" + config.orgId + "/project/" + config.projectId + "/otdr/results";
            return $http[method](url);
        }

        function getOTDRTestResultsByFibergroup(config) {
            var method = "get";
            var url = baseUrl + "/organization/" + config.orgId + "/project/" + config.projectId + "/fiberGroup/" + config.fiberGroupId + "/otdr/results";
            return $http[method](url);
        }

        function getProjectEquipment(orgId, projectId) {
            return $http.get(baseUrl + '/organization/' + orgId + '/project/' + projectId + '/equipment');
        }

        function createProjectEquipment(orgId, projectId, body) {
            var method = "post";
            var url = baseUrl + '/organization/' + orgId + '/project/' + projectId + '/equipment';
            return $http[method](url, body);
        }

        function updateProjectEquipment(orgId, projectId, equipmentId, body) {
            var method = "put";
            var url = baseUrl + '/organization/' + orgId + '/project/' + projectId + '/equipment/' + equipmentId;
            return $http[method](url, body);
        }

        function deleteProjectEquipment(orgId, projectId, equipmentId) {
            return $http.delete(baseUrl + '/organization/' + orgId + '/project/' + projectId + '/equipment/' + equipmentId);
        }

        function unmarkFiberGroupFromDelete(orgId, projectId, fiberGroupId) {
            return $http.put(baseUrl + '/organization/' + orgId + '/projects/' + projectId + '/fibergroups/' +
                fiberGroupId + '/unmark');
        }

        function getConfig() {
            return $http.get(baseUrl + "/config");
        }

        var reportAPI = {
            getRunningNLatestReports: function getRunningNLatestReports(orgId, projectId, count) {
                return $http.get(baseUrl + '/organization/' + orgId + '/projects/' + projectId + '/projectreport?count=' + count);
            }
        };

        // This is a mapping between Controller and Factory Service and functions defined above.
        var api = {
            authenticate: authenticate,
            loadOrganization: loadOrganization,
            loadOrganizationUsers: loadOrganizationUsers,
            loadOrganizationUsersWithRoles: loadOrganizationUsersWithRoles,
            loadOrganizationUserInfo: loadOrganizationUserInfo,
            updateContactInformation: updateContactInformation,
            addRogueUnit: addRogueUnit,
            getRogueUnits: getRogueUnits,
            deleteRogueUnit: deleteRogueUnit,
            getOrganizationLicenses: getOrganizationLicenses,
            addLicenseToOrganization: addLicenseToOrganization,
            addUserToLicense: addUserToLicense,
            removeUserFromLicense: removeUserFromLicense,
            activateLicense: activateLicense,
            inviteUser: inviteUser,
            editUser: editUser,
            inviteProjectUser: inviteProjectUser,
            inviteProjectUsers: inviteProjectUsers,
            editProjectUser: editProjectUser,
            inviteTechUser: inviteTechUser,
            userInvitationEmail: userInvitationEmail,
            sendResetEmail: sendResetEmail,
            notifyTeamMembers: notifyTeamMembers,
            deleteUser: deleteUser,
            deleteProjectUser: deleteProjectUser,
            loadProjects: loadProjects,
            loadHiddenProjects: loadHiddenProjects,
            loadActiveAndHiddenProjects: loadActiveAndHiddenProjects,
//			getProjectChanged: getProjectChanged,
            loadProject: loadProject,
            loadProjectUsers: loadProjectUsers,
            loadProjectSummary: loadProjectSummary,
            loadProjectResults: loadProjectResults,
            loadFiberGroupResults: loadFiberGroupResults,
            loadProjectResultsReport: loadProjectResultsReport,
            loadImageMetadata: loadImageMetadata,
            getExportSorUrl: getExportSorUrl,
            exportSorFile: exportSorFile,
            importSorFiles: importSorFiles,
            loadProjectUserInfo: loadProjectUserInfo,
            deleteFiberGroups: deleteFiberGroups,
            duplicateFiberGroups: duplicateFiberGroups,
            generateTestResults: generateTestResults,
            removeTestSetupFromFiberGroup: removeTestSetupFromFiberGroup,
            createFiberGroup: createFiberGroup,
            editProject: editProject,
            copyProject: copyProject,
            mergeProjects: mergeProjects,
            updateProjectStatus: updateProjectStatus,
            deleteProject: deleteProject,
            clearProjectMergeFlag: clearProjectMergeFlag,
            addTestSetupToFiberGroups: addTestSetupToFiberGroups,
            deleteFiberGroup: deleteFiberGroup,
            saveFiberGroup: saveFiberGroup,
            createProject: createProject,
            createTestSetup: createTestSetup,
            getTestSetups: getTestSetups,
            getAllTestSetups: getAllTestSetups,
            toggleTestSetupStatus: toggleTestSetupStatus,
            createTestLimit: createTestLimit,
            getTestLimits: getTestLimits,
            getTestLimitsWithArchived: getTestLimitsWithArchived,
            toggleTestLimitStatus: toggleTestLimitStatus,
            requestReport: requestReport,
            requestNewReport: requestNewReport,
//			isReportReady: isReportReady,
            getAllContacts: getAllContacts,
            getContactById: getContactById,
            createContact: createContact,
            editContact: editContact,
            setContactLogo: setContactLogo,
            deleteContactLogo: deleteContactLogo,
            updateContactStatus: updateContactStatus,
            getAllProjectContacts: getAllProjectContacts,
            getProjectContactById: getProjectContactById,
            createProjectContact: createProjectContact,
            editProjectContact: editProjectContact,
            deleteProjectContact: deleteProjectContact,
            requestPreview: requestPreview,
//			pollPreview: pollPreview,
            getPreview: getPreview,
            getAllReportConfigs: getAllReportConfigs,
            createReportConfig: createReportConfig,
            updateReportConfig: updateReportConfig,
            getRememberReportConfig: getRememberReportConfig,
            saveRememberReportConfig: saveRememberReportConfig,
            sendEmail: sendEmail,
//			saveAttachment: saveAttachment,

            createAttachmentCollection: createAttachmentCollection,
            updateAttachmentCollectionName: updateAttachmentCollectionName,
            deleteAttachmentCollection: deleteAttachmentCollection,
            getAttachmemtCollectionDetails: getAttachmemtCollectionDetails,
            addAttachmentToCollection: addAttachmentToCollection,
            updateAttachment: updateAttachment,
            deleteAttachment: deleteAttachment,

            getOrgToolsByType: getOrgToolsByType,
            getProjectToolsByType: getProjectToolsByType,
            applyProjectToolToFiberGroupsByToolType: applyProjectToolToFiberGroupsByToolType,
            removeProjectToolFromFiberGroupsByToolType: removeProjectToolFromFiberGroupsByToolType,
            createProjectToolByType: createProjectToolByType,
            updateProjectToolByType: updateProjectToolByType,
            deleteProjectToolByType: deleteProjectToolByType,
            getToolsAppliedToProject: getToolsAppliedToProject,

            getFiberTypes: getFiberTypes,

            getTraceViewerData: getTraceViewerData,
            getTraceViewerEvents: getTraceViewerEvents,
            getOTDRTestResults: getOTDRTestResults,
            getOTDRTestResultsByFibergroup: getOTDRTestResultsByFibergroup,
            getSimpleFiberTypes: getSimpleFiberTypes,
            getProjectEquipment: getProjectEquipment,
            createProjectEquipment: createProjectEquipment,
            updateProjectEquipment: updateProjectEquipment,
            deleteProjectEquipment: deleteProjectEquipment,
            unmarkFiberGroupFromDelete: unmarkFiberGroupFromDelete
        };

        _.extend(api, {
            baseUrl: baseUrl,
            getProjectChanged: getProjectChanged,
            isReportReady: isReportReady,
            pollPreview: pollPreview,
            otdrToolLibraryAPI: otdrToolLibraryAPI,
            genericToolLibraryAPI: genericToolLibraryAPI,
            reportAPI: reportAPI,
            getConfig: getConfig
        });
        return api;
    }]);
